// Import React components
import React, { useState, useEffect, useCallback, useRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material";

//Background Image
import Tilt from 'react-parallax-tilt';

import AppGrid from "./AppGrid";
import { Content } from "./Content";
import ContentFacade from "./ContentFacade"

import "./App.css";
import appData from './AppData.json';

import Battery6BarOutlinedIcon from '@mui/icons-material/Battery6BarOutlined';
import WifiIcon from '@mui/icons-material/Wifi';

appData.apps = appData.apps.map((app_obj, index) => {
  app_obj["index"] = index;
  return app_obj;
});

const THEME = createTheme({
  typography: {
    "fontFamily": `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
   Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
  },
  palette: {
    mode: 'dark',
  },
});


export default function App() {
  const [activeContentIndex, setActiveContentIndex] = useState(-1);
  const [lastOpened, setLastOpened] = useState(0);
  const iconRefs = useRef({});
  const facadeRef = useRef();
  const appGridRef = useRef();
  const backgroundRef = useRef();
  const backgroundImageRef = useRef();

  const handleClick = useCallback((index) => {
    const swiper = document.querySelector('.swiper').swiper;
    if (index === -1) {
      swiper.allowSlideNext = true;
      swiper.allowSlidePrev = true;
      swiper.pagination.el.style.opacity = 1;
    }
    else {
      swiper.allowSlideNext = false;
      swiper.allowSlidePrev = false;
      swiper.pagination.el.style.opacity = 0;
    }
    setActiveContentIndex(index);
  }, []);

  var [date, setDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 60000)
    return function cleanup() {
      clearInterval(timer);
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={THEME}>
        <div ref={backgroundRef} className="h-full w-full blurFilter" />
        <ContentFacade lastOpened={lastOpened} setLastOpened={setLastOpened} facadeRef={facadeRef} appGridRef={appGridRef} backgroundRef={backgroundRef} backgroundImageRef={backgroundImageRef} activeContentIndex={activeContentIndex} iconRefs={iconRefs} />
        {appData.apps.map((app) => app.type === "app" && (
          <Content active={app.index === activeContentIndex} handleClickWrapper={handleClick} key={"app-" + app.index + "-content"} content={app.content} />
        ))}
        <div ref={appGridRef} className="absolute self-center app_grid_zoom_container h-full w-full flex justify-around">
          <AppGrid facadeRef={facadeRef} handleClickWrapper={handleClick} appData={appData} iconRefs={iconRefs} />
        </div>
        <div className="fixed top-1 left-0 w-full pr-3 pl-2">
          <p className="float-left text-white text-[2vh] sm:text-sm md:text-base lg:text-lg">
            {date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
          })} &nbsp; {date.toLocaleDateString('en-US', {
            weekday: 'short',
            month: 'short',
            day: 'numeric'
          })}</p>
          <p className="float-right text-white text-[2vh] sm:text-sm md:text-base lg:text-lg"><WifiIcon fontSize="inherit" sx={{ marginRight: "1vh", transform: "scale(1.4)", transformOrigin: "center" }} /> 100% &nbsp; <Battery6BarOutlinedIcon fontSize="inherit" sx={{ transform: "rotate(90deg) scale(2)", transformOrigin: "center" }} /></p>
        </div>
        <div ref={backgroundImageRef} className="player-wrapper background_image_close origin-center">
          <Tilt tiltMaxAngleX={4} tiltMaxAngleY={4} tiltReverse={true} perspective={1000} trackOnWindow={true} className="tilt-wrapper">
            <img src="images/ios_background.jpg" className="player-image" alt="Looks like the background didn't load :("></img>
          </Tilt>
        </div>
      </ThemeProvider>
    </>
  );
}

// Import React components
import React, { useState, useEffect } from "react";
import { debounce } from "lodash";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import "./App.css";
import "./ContentFacade.css"
import Widget from "./Widget";

function AppGrid({ facadeRef, handleClickWrapper, appData, iconRefs }) {
    var grid_width_factor = 0.85;
    var grid_height_factor = 0.95;
    var min_col_gap_factor = 1;
    const min_row_gap_factor = 0.5;

    function sliceIntoChunks(arr, chunkSize) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    function recomputeLayout() {

        var grid_size = 75; //size of 1x1 grid square in pixels
        if (window.innerHeight >= 600) {
            if (window.innerWidth > 1800) {
                grid_size = 170;
                min_col_gap_factor = 1;
            } else if (window.innerWidth >= 1600) {
                grid_size = 130;
                min_col_gap_factor = 1;
            } else if (window.innerWidth >= 1280) {
                grid_size = 120;
                min_col_gap_factor = 1;
            } else if (window.innerWidth >= 1024) {
                grid_size = 115;
                min_col_gap_factor = 1;
            } else if (window.innerWidth >= 768) {
                grid_size = 105;
                min_col_gap_factor = 0.5;
            } else if (window.innerWidth >= 640) {
                grid_size = 90;
                min_col_gap_factor = 0.5;
            }
        }

        const min_col_gap = min_col_gap_factor * grid_size; //minimum gap between columns
        const min_row_gap = min_row_gap_factor * grid_size; //minimum gap between rows
        const app_grid_width = Math.floor(grid_width_factor * window.innerWidth); //available width on screen for app grid
        const app_grid_height = Math.floor(grid_height_factor * (window.innerHeight - 1.2 * grid_size - 40)); //available height on screen for app grid, leave a row at bottom for dock and a lil more for pagination

        /* Where the magic happens. At minimum, we want a 2x2 grid so that title widget can be shown on first slide no matter
        how small the screen size gets.  If a user accesses this site on a [L]umia, they are a walking L.*/
        const num_cols = Math.max(2, Math.round((app_grid_width + min_col_gap) / (grid_size + min_col_gap))); //num_cols * grid_size + (num_cols-1) * min_col_gap <= screenSize.width
        const num_rows = Math.max(2, Math.round((app_grid_height + min_row_gap) / (grid_size + min_row_gap)));

        const widgetsAllowedPerSlide = (Math.floor(num_rows / 2)) * (Math.floor(num_cols / 2));
        const widgetsSplit = sliceIntoChunks(appData.widgets, widgetsAllowedPerSlide);
        const slidesTemp = widgetsSplit.map((widget) => ({ widgets: widget, apps: [] }));

        var curr_slide = 0;
        const app_stack = appData.apps.slice().reverse();
        while (app_stack.length > 0) {
            if (((num_rows * num_cols) - (4 * slidesTemp[curr_slide].widgets.length) - slidesTemp[curr_slide].apps.length) <= 0) {
                if (curr_slide >= slidesTemp.length - 1) {
                    slidesTemp.push({});
                    curr_slide++;
                    slidesTemp[curr_slide].widgets = [];
                    slidesTemp[curr_slide].apps = [];
                } else {
                    curr_slide++;
                }
            }
            slidesTemp[curr_slide].apps.push(app_stack.pop());
        }
        return {
            slideLayout: slidesTemp,
            num_rows: num_rows,
            num_cols: num_cols,
            grid_size: grid_size,
            app_grid_height: app_grid_height,
            app_grid_width: app_grid_width,
            min_col_gap: min_col_gap,
            min_row_gap: min_row_gap
        };
    }

    const [slides, setSlides] = useState(recomputeLayout());

    useEffect(() => {
        const updateDimension = debounce(() => {
            setSlides(recomputeLayout());
        }, 200);
        window.addEventListener('resize', updateDimension);
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    });

    return (
        <>
            <div className="screenContainer">
                <Swiper
                    direction={"horizontal"}
                    slidesPerView={1}
                    spaceBetween={0}
                    mousewheel={true}
                    pagination={{
                        clickable: true,
                        type: 'bullets',
                        dynamicBullets: true,
                        dynamicMainBullets: 2,
                    }}
                    modules={[Mousewheel, Pagination]}
                    style={{
                        "--swiper-pagination-bottom": `${window.innerWidth < 768 ? Math.floor(1.1 * slides.grid_size) : slides.grid_size}px`
                    }}
                >
                    {slides.slideLayout.map((slide, slideIndex) =>
                        <SwiperSlide key={"swiperlide-" + slideIndex} slide_number={slideIndex}>
                            <div key={"screen-" + slideIndex} className="flex items-center justify-center h-full w-full">
                                <div className={"grid grid-flow-row-dense items-center content-start"} style={{ gap: `${Math.floor(slides.min_row_gap)}px ${Math.floor(slides.min_col_gap)}px`, width: `${slides.app_grid_width}px`, height: `${slides.app_grid_height}px`, gridTemplateColumns: `repeat(${slides.num_cols}, minmax(0, 1fr))` }}>
                                    {slide.widgets != null && slide.widgets.map((widget, widgetIndex) => (
                                        <Widget widget_content={widget.content} grid_size={slides.grid_size} key={"widget-" + slideIndex + "-" + widgetIndex} />
                                    ))}
                                    {slide.apps.map((app, appIndex) => (
                                        <center key={"appcenter-" + slideIndex + "-" + appIndex + "-center"} className="col-span-1 row-span-1">
                                            <div ref={el => iconRefs.current[app.index] = el} onClick={app.type === "app" ? (e) => {
                                                handleClickWrapper(app.index);
                                            } : undefined}
                                                className="aspect-square rounded-[20%] overflow-hidden outline-gray-50/25 outline-4 outline-hidden bg-transparent hover:outline hover:bg-gray-50/25 focus:outline focus:bg-gray-50/25 cursor-pointer" style={{ width: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px`, height: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px` }}>
                                                {app.name === "Clock" ? <object aria-label="Clock" data={app.icon} style={{ pointerEvents: "none" }} type="image/svg+xml" /> :
                                                    (app.type === "app" ? <img src={app.icon} alt={app.name} className="object-cover aspect-square" id={"app_icon-" + app.index} /> :
                                                        <a href={app.content} target="_blank" rel="noopener noreferrer" className="rounded-[20%] overflow-hidden cursor-pointer"><div className="aspect-square" style={{ width: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px`, height: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px` }}><img alt={app.name} src={app.icon} className="object-cover"></img></div></a>)}
                                            </div>
                                            <p className="text-sm md:text-md lg:text-lg text-white text-center [text-shadow:_0_0_1rem_black]">{app.name}</p>
                                        </center>
                                    ))}
                                </div>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
            <div className="screenContainer pointer-events-none">
                <div className="pointer-events-auto absolute flex justify-center bottom-[1vh] left-0 mx-auto inset-x-0 items-bottom">
                    <div className="grid bg-gray-50/25 backdrop-blur-3xl flex place-items-center justify-items-center grid-cols-4 grid-flow-dense" style={{ borderRadius: `${0.2 * (window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size))}px`, padding: `${Math.floor(0.11 * slides.grid_size)}px`, columnGap: `${Math.floor(0.11 * slides.grid_size)}px` }}>
                        <a href="https://www.facebook.com/vinayvkukutla/" target="_blank" rel="noopener noreferrer" className="rounded-[20%] overflow-hidden hover:contrast-75 cursor-pointer" ><div className="aspect-square" style={{ width: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px`, height: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px` }}><img alt="Facebook" src="/ios_icons/facebook.jpg" className="object-cover"></img></div></a>
                        <a href="https://www.instagram.com/vinaykukutla/" target="_blank" rel="noopener noreferrer" className="rounded-[20%] overflow-hidden hover:contrast-75 cursor-pointer"><div className="aspect-square" style={{ width: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px`, height: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px` }}><img alt="Instagram" src="/ios_icons/instagram.jpg" className="object-cover"></img></div></a>
                        <a href="https://www.linkedin.com/in/vinaykukutla/" target="_blank" rel="noopener noreferrer" className="rounded-[20%] overflow-hidden hover:contrast-75 cursor-pointer"><div className="aspect-square" style={{ width: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px`, height: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px` }}><img alt="LinkedIn" src="/ios_icons/linkedin.svg" className="object-cover"></img></div></a>
                        <a href="mailto:vinay.v.kukutla@gmail.com" target="_blank" rel="noopener noreferrer" className="rounded-[20%] overflow-hidden hover:contrast-75 cursor-pointer"><div className="aspect-square" style={{ width: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px`, height: `${window.innerWidth < 768 ? Math.floor(0.65 * slides.grid_size) : Math.floor(0.5 * slides.grid_size)}px` }}><img alt="Mail" src="/ios_icons/mail-2365225.svg" className="object-cover"></img></div></a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.memo(AppGrid);
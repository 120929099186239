import './ContentFacade.css';
import { useEffect, useState } from 'react';
// var app_transform = '';

export default function ContentFacade({ appGridRef, facadeRef, backgroundRef, backgroundImageRef, activeContentIndex, iconRefs, lastOpened, setLastOpened }) {
  const [firstRender, setFirstRender] = useState(true);
  const [app_transform, set_app_transform] = useState('');

  const openApp = function (appIndex) {
    setLastOpened(appIndex);
    const appContainer = facadeRef.current;
    const app_bounding_rect = appContainer.getBoundingClientRect();
    const tile_bounding_rect = iconRefs.current[appIndex].getBoundingClientRect();
    const translateX = tile_bounding_rect.left + 'px';
    const translateY = tile_bounding_rect.top + 'px';
    const scaleX = tile_bounding_rect.width / app_bounding_rect.width;
    const scaleY = tile_bounding_rect.height / app_bounding_rect.height;
    const temp = "translate3d(" + translateX + "," + translateY + ",0) scale(" + scaleX + ", " + scaleY + ")";
    set_app_transform(temp);
    appContainer.style.transform = temp;
    // eslint-disable-next-line
    const dummy = appContainer.offsetWidth; // force reflow
    appContainer.classList.add("-app-open");
    appGridRef.current.classList.add("app_grid_app_open");
    backgroundImageRef.current.classList.remove("background_image_close");
    backgroundImageRef.current.classList.add("background_image_open");
    backgroundRef.current.classList.add("background_open");
    appContainer.style.transform = "translate3d(0,0,0) scale(1)";
  }

  const closeApp = function () {
    const appContainer = facadeRef.current;
    appContainer.addEventListener('transitionend', resetApp, false);
    appContainer.style.transform = app_transform;
    appContainer.classList.add("-app-close");
    appContainer.classList.remove("-app-open");
    appGridRef.current.classList.remove("app_grid_app_open");
    backgroundImageRef.current.classList.remove("background_image_open");
    backgroundImageRef.current.classList.add("background_image_close");
    backgroundRef.current.classList.remove("background_open");
    appGridRef.current.classList.add("app_grid_app_close");
    backgroundRef.current.classList.add("background_close");
  }

  const resetApp = function () {
    appGridRef.current.classList.remove("app_grid_app_close");
    backgroundRef.current.classList.remove("background_close");
    const appContainer = facadeRef.current;
    appContainer.classList.remove("-app-close");
    appContainer.removeAttribute('style');
    appContainer.removeEventListener('transitionend', resetApp);
  }

  useEffect(() => {
    if (!firstRender) {
      if (activeContentIndex !== -1) {
        openApp(activeContentIndex);
      }
      else {
        closeApp();
      }
    }
    else {
      setFirstRender(false);
    }
    // eslint-disable-next-line
  }, [activeContentIndex]);

  return (
    <>
      <div className="c-app-container" ref={facadeRef} />
    </>
  )
}

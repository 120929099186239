import { Suspense, lazy } from "react";
import React from "react";
import "./Widget.css"

function Widget(props) {
    const MyComponent = lazy(() => import("./app_content/" + props.widget_content + ".js"));
    return (
        <Suspense fallback={
            <div className="col-span-2 row-span-2 flex place-content-center">
                <div className="place-content-center aspect-square rounded-3xl overflow-hidden bg-gray-50/25 backdrop-blur-lg" style={{ width: `${2 * props.grid_size}px`, height: `${2 * props.grid_size}px` }}>
                    <div className="spinner center">
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                    </div>
                </div>
            </div>}>
            <MyComponent grid_size={props.grid_size} />
        </Suspense>
    );
}

export default React.memo(Widget);
import React from "react";
import { lazy, Suspense, useState, useEffect } from "react";
import { useMemo } from 'use-memo-one';
import './Content.css';
import './Widget.css';
import { Fab } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

function ContentInternal({ content, handleClickWrapper, active }) {
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    if (!showComponent && active) {
      setShowComponent(true);
    }
  }, [active, showComponent]);

  const MyComponent = useMemo(() => lazy(() => import("./app_content/" + content + "/" + content + ".js")), [content]);
  //const MyComponent = null;

  return (
    <>
      <div className={"bg-transparent content_container " + (active ? "show_container" : "hide_container")}>
        <div className="absolute z-1 bottom-[6rem] w-full flex justify-center text-center">
          <Fab sx={{
            background: 'rgb(249, 250, 251, 0.50)',
            backdropFilter: 'blur(64px)',
            height: '4rem',
            width: '4rem',
            boxShadow: '0px 0px 1rem black'
          }} aria-label="home" onClick={(e) => {
            e.stopPropagation();
            handleClickWrapper(-1);
          }}>
            <HomeIcon sx={{ fontSize: '2rem' }} />
          </Fab>
        </div>
        <Suspense className="absolute z-0 " fallback={
          <div className="col-span-2 row-span-2 flex place-content-center">
            <div className="spinner center">
              <div className="spinner-blade"></div>
              <div className="spinner-blade"></div>
              <div className="spinner-blade"></div>
              <div className="spinner-blade"></div>
              <div className="spinner-blade"></div>
              <div className="spinner-blade"></div>
              <div className="spinner-blade"></div>
              <div className="spinner-blade"></div>
              <div className="spinner-blade"></div>
              <div className="spinner-blade"></div>
              <div className="spinner-blade"></div>
              <div className="spinner-blade"></div>
            </div>
          </div>}>
          {showComponent && <MyComponent />}
        </Suspense>
      </div>
    </>
  );
}

export const Content = React.memo(ContentInternal);

// export default React.memo(Content);

